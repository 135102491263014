import React from "react"
import MainLayout from "../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../components/ContactHeading"
import ListWithDescription from "../components/ListWithDescription"

export const pageQuery = graphql`
  query {
    accessControlBackgroundImage: file(
      relativePath: { eq: "access-control-background.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    accessControlInstallationImage: file(
      relativePath: { eq: "access-control-installation.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    accessControlKeypadImage: file(
      relativePath: { eq: "access-control-keypad.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    accessControlBluetoothImage: file(relativePath: { eq: "access-control-bluetooth.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const servicesData = [
  {
    heading: "Electronic Access Key Pads",
    description:
      "This simple but effective lock system is a perfect replacement for your traditional door lock. It only grants access in and out of doors when the right code combinations have been inputted.  The door automatically locks in split seconds after the right combination has been inputted, hence, you never again have to worry about unauthorized personnel gaining access into reserved areas of your offices and business centers.",
  },
  {
    heading: "Electronic Dead Bolt Locks",
    description:
      "If you are a fan of dead bolt locks, this option is just for you. Dead bolts can be serve as effective solutions as they are very functional lock options. A dead bolt lock can very easily keep you out of your premises if you forget or misplace your keys. Thankfully, the Electronic dead bolt lock comes with a keypad function that allows you open your door through code combinations. Thus, you can be rest assured of your safety, and convenience when you use an electric dead bolt lock.",
  },
  {
    heading: "Bluetooth Wireless Locks",
    description:
      "In the age of mobile devices, you can control your door with your smart phone too. This is possible if you get a Bluetooth wireless smart lock for your doors. End all of the worrying once and for all. Start monitoring your doors with your mobile device. With a Bluetooth wireless lock, you can assign long term and one time opening codes from your phone, and open your door by swiping your phone over the lock.",
  },
  {
    heading: "Magnetic Lock System",
    description:
      "A magnetic lock system is a very secure option when it comes to security. The magnetic door works with a magnet which keeps the door securely locked as long as electric current is being supplied to the magnet. With a magnetic lock system, you can keep a door locked on both sides.",
  },
  {
    heading: "Digital Access Keys",
    description:
      "The digital Access key is built to open when prompted by a configured mobile device. This is a more suitable lock option for businesses such as hotels and guest houses. This may be attributed to the ease of use and easy monitoring by hotel management. To use digital access keys, all you have to do is put your device next to the door. You can carry your safety in your pocket all day long.",
  },
  {
    heading: "Proxy Cards and Key Fobs",
    description:
      "With proxy cards and key fobs, individuals can gain access into a place just by swiping provided cards or the key fobs as these doors have inbuilt sensors which detect cards or fobs from a set distance.  This is a great lock solution for an area where a lot of individuals need to use doors. Make things easy with cards and key FOBs. Contact Swift Locksmith today.",
  },
  {
    heading: "Access Logging System",
    description:
      "Access logging systems can be used in office spaces with restricted areas. They are also used to track information such as entry/exit time of employees. These kind of systems function with either keypads or biometrics which allow only registered and recognizable persons in.",
  }
]

function AccessControlPage({ data }) {
  return (
    <MainLayout
      title="Access Control | 24 Hour Professional Locksmith Sacramento"
      description="Offer a variety of access control options to better secure your home & business. Digital access keypads, magnetic lock systems, and more."
      offset
    >
      <ContactHeading
        background={data.accessControlBackgroundImage.childImageSharp.fluid}
      >
        <h1>Get the Best Access Control Locksmith Services in Sacramento</h1>
      </ContactHeading>
      <Container as="section">
        <h2 className="text-center">Secure Your Business with Access Control Locks</h2>
        <p className="indent">
          One of the greatest concerns of the modern day human is safety. The safety of their businesses, houses and offices. At Swift locksmith, we have taken adequate steps to develop some of the finest access control solutions in the U.S. Some of these solutions include Digital Access Keys, Proxy Card & Key FOBs, Magnetic Lock Systems and access Logging Systems to mention but a few.
        </p>
        <p className="indent mb-0">
          Access Control locks remain one of the best options with regards to physical security.  With our access control locks and devices, you can completely limit and control who goes in or out of your business premises or home. This ensures that you have complete control over the security of your business.
        </p>
      </Container>
      <section>
        <ListWithDescription
          items={servicesData}
          heading="Access Control Systems Available"
        />
      </section>
      <Container as="section" className="d-none d-sm-block">
        <Row noGutters>
          <Col xs={4}>
            <Img
              fluid={data.accessControlInstallationImage.childImageSharp.fluid}
              alt="access-control-installation"
              style={{ maxWidth: "100%" }}
            />
          </Col>
          <Col xs={4}>
            <Img
              fluid={data.accessControlKeypadImage.childImageSharp.fluid}
              alt="access-control-keypad"
              style={{ maxWidth: "100%" }}
            />
          </Col>
          <Col xs={4}>
            <Img
              fluid={data.accessControlBluetoothImage.childImageSharp.fluid}
              alt="access-control-bluetooth"
              style={{ maxWidth: "100%" }}
            />
          </Col>
        </Row>
      </Container>
      <Container as="section" className="mb-5">
        <h2>24 Hour Professional Locksmith Services</h2>
        <p className="indent">
          Reach out to us today to get top notch access control solutions. Our services remain unrivaled thanks to a dedicated team of lock specialists backed with years of experience.  Give us a call today to consult with our customer service representatives on information regarding installation and maintenance of your access control locks.
        </p>
        <p className="indent">
          Our well experienced team of locksmiths are always ready to offer their expertise, skills, and services in a bid to ensure that you get the best results possible. Plus, you can call on us whenever to aid you with whatever emergencies you encounter.
        </p>
        <p className="indent mb-0">
          You don't just get the best services with Swift Locksmith, you get the best pricing as well. At Swift locksmith, we believe in functionality, hence, you do not have to empty your coffers to get a suitable access control solution. Reach out to us today for the best of services in Sacramento and its environs.
        </p>
      </Container>
    </MainLayout>
  )
}

export default AccessControlPage
